.verticalContent {
    height: 100%;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    user-select: none;
  }

  .ant-timeline-item.ant-timeline-item-left {
    /* font-size: 1vw; */
  }
  .livePage .ant-timeline .ant-timeline-item {
    font-size: 1em;
    color:white;
  }

  .contentSubPage {
    overflow-y:auto;
    overflow-x:hidden;
    height: 100%;

}

.contentSubPage .container {

  width: 100%;

}
.contentSubPageBackground {
transition: background-position 0.0s ease, background-size 1.5s ease;
background-repeat: no-repeat;
}

.livePage * {color:#7b7b7b}
.livePage p,.livePage h4 {margin-bottom: 1em;}
.livePage p {font-size:1rem; text-shadow: rgb(28 28 28) 0px 1px 1px;}

.livePage .ant-statistic-title {color:#7b7b7b;font-size: 1em;}
.livePage .ant-statistic-content {font-size: 2em;margin-bottom:10px}
.livePage .ant-statistic-content-value {color:white}
.livePage .small-counter .ant-statistic-content-value {font-size: 0.7em;
  color: white;
  filter: blur(2px);}



.livePage .dataTime {
text-align: right;
font-size: 9px;
border-bottom: 1px solid rgb(89, 89, 89);
margin: 5px;
/* background-color: #ffffff3b; */
color: white;
}