/* W pliku styles.css */
body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column; /* Dla odpowiedniego ustawienia flexbox */
  justify-content: center;
  align-items: center;
  min-height: 100dvh;
}

.layout-container {
  text-align: center; /* Opcjonalne: Jeśli chcesz, aby zawartość była wyśrodkowana w poziomie */
}


.content-container {
  /* Twoje style dla kontenera zawierającego komponent */
  display: flex;
  flex: 1; /* Wypełnia dostępną przestrzeń, ale nie wpływa na przewijanie strony */
  justify-content: center;
  align-items: center;
}

/* Twoje style dla komponentu */
.MOJ-komponent {
  max-width: 100%; /* Ogranicza maksymalną szerokość komponentu */
  height: auto; /* Zachowuje proporcje oryginalne */
}





.scrollbar-thin {
  scrollbar-width: 22px;
  scrollbar-color: transparent
}
/* .scrollbar-thin::-webkit-scrollbar {
  display: none;
} */

.scrollbar-thin::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa; /* or add it to the track */
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background: #000;
}
.centered-block {
display: flex;
align-items: center;
justify-content: center;
}