

.rain {
  position: absolute;
   left: 0;
   top: 0;
  width: 100%;
  height: 100%; 
  overflow: hidden;
  pointer-events: none; 
  z-index: 1;
}
.rain__drop {
  -webkit-animation-delay: calc(var(--d) * 5s);
          animation-delay: calc(var(--d) * 5s);
  -webkit-animation-duration: calc(var(--a) * 4s);
          animation-duration: calc(var(--a) * 4s);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: drop;
          animation-name: drop;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  height: 1dvh;
  
  left: calc(var(--x) * 1%);
  position: absolute;
  top: calc((var(--y) + 50) * -1px);
}
.rain__drop path {
  fill: #b9b9b99c;
  opacity: var(--o);
  transform: scaleY(calc(var(--s) * 0.2));
}
@-webkit-keyframes drop {
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(100vh);
  }
}
@keyframes drop {
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(100vh);
  }
}
