.safe-dials {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.dial {
    position: relative;
    margin: 0 15px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dial-number {
    position: absolute;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: 50% 40px; /* Odstęp od środka okręgu */
    text-align: center;
}
