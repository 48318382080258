.homeContener {
  display: flex;
  flex-direction: column; /* Układa elementy dzieci w kierunku pionowym */
  height: 100dvh; /* Zajmuje całą dostępną wysokość ekranu */
  overflow: hidden; /* Ukrywa wszelkie zawartości wychodzące poza kontener */
}

.homeContener > :first-child {
  flex: 1; /* Zajmuje całą dostępną przestrzeń poza drugim dzieckiem */
  overflow: hidden; /* Umożliwia przewijanie, jeśli zawartość jest za duża */
}

.homeContener > :last-child {
  height: 10dvh; /* Ustala stałą wysokość dla dolnego elementu */
}


.homeContener {
  position: relative; /* Ustala pozycję kontenera */
}

.homeContener {
  
  aspect-ratio: 375/697;
  width: 100vw; /* Domyślnie szerokość 100% */
}
@media (min-width: 500px) {
  .homeContener {
      width: 100%; /* Jeśli szerokość ekranu jest większa lub równa 500px, użyj szerokości auto (domyślna szerokość elementu) */
      aspect-ratio: 375/697;
      /* min-width: 500px; Ustal minimalną szerokość na 500px */
  }
}


.fog {
  position: absolute;
 
  left: 0;
  width: 100%;
  height: 100%; 
  overflow: hidden;
  pointer-events: none; 
}

.fog.bottom-fog {
  
  bottom: 10dvh; 
  
}

.fog.top-fog {
  rotate:180deg;
  z-index: 1;
}

/* .fogImage.left {
  animation-name: fog_effect_left;
}

.fogImage.right {
  animation-name: fog_effect_right;
} */

.fog img {
  position: absolute;
  bottom: 0;
  max-width: 100%;
  animation: fog_effect calc(5s * var(--i)) ease-in infinite;
  margin-left:-50%;
  /* margin-left:calc(20% * var(--i)) */
  
}

@keyframes fog_effect {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  20%{
    opacity: 0.1;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}

.mask {
  bottom: 0px;
  height: 38px;
  position: absolute;
  width: 100%;
  background-size: 50% 100%;
}

@keyframes moveBackground2 {
  from { background-position-x: 0; }
  to { background-position-x: 200%; }
}

.mask {
  background-image: url('./img/fog-up-bg.png');
  
  background-repeat: repeat-x;
  animation: moveBackground2 60s linear infinite;
  transition: background-size 3s linear;
}

/* @keyframes fog_effect_left {
  0% {
    transform: scale(1) ;
    opacity: 0;
  }
  25% {
    transform: scale(1.5) ; 
    opacity: 1; 
  }
  75% {
    transform: scale(2.25) ; 
    opacity: 1; 
  }
  100% {
    transform: scale(3) ; 
    opacity: 0;
  }
}

@keyframes fog_effect_right {
  0% {
    transform: scale(1) translateX(0);
    opacity: 0;
  }
  25% {
    transform: scale(1.5) translateX(5%); 
    opacity: 1; 
  }
  75% {
    transform: scale(2.25) translateX(15%); 
    opacity: 1; 
  }
  100% {
    transform: scale(3) translateX(20%);
    opacity: 0;
  }
} */

.fogContainer {
  position: relative;
  z-index: 10; /* Aby upewnić się, że mgła jest nad SwipeableViews */
  overflow: hidden;
}

/* .fogImage {
  position: absolute;
  bottom: 0;
  width: 100%;
  animation: fogEffect 10s ease-in infinite;
  
  pointer-events: none; 

} */

/* @keyframes fogEffect {
  0%, 100% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(2);
  }
} */

/* .container {
  scroll-snap-type: x mandatory;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  height: 100vh;
  overflow-y: hidden;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth; 
}

.section {
  scroll-snap-align: start;
  scroll-snap-stop: always;
  width: 100%;
  height: 90vh;
  flex-shrink: 0;
  font-size: 24px;
  background-color: #f0f0f0;
}


.section.active {
  background-color: #f0f0f0;
  color: #333;
} */

.contentSubPageBackground {
  background-size: cover;
      background-position: center;
      background-position: center;
      height: calc(90dvh);
}