body {
  background: black;
}

.container {
  position: relative;
  padding: 0px;
  /* height: 70%; */
  width: 90%;
  aspect-ratio: 1/1;
}

.compass-content {
  overflow: hidden;
  position: absolute;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  color: #fff;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: radial-gradient(ellipse at center, rgb(107 103 167 / 0%) 24%, rgb(0 0 0 / 58%) 59%);
  overflow: hidden;
  transition: background-position 1.3s ease, background-size 2.3s ease;
}

.circle {
  background-size: cover, cover;
  background-position: center, center;
  overflow: hidden;
}

.circle::before,
.circle::after {
  content: '';
  position: absolute;
  border-radius: 50%;
}

.circle::before {
  /* Zewnętrzne obramowanie */
  top: -0.1em;
  left: -0.1em;
  right: -0.1em;
  bottom: -0.1em;
  border: 0.1em solid rgba(206, 230, 255, 0.549)
    /* kolor obramowania */
}

.circle::after {
  /* Wewnętrzne obramowanie */
  top: 0.1em;
  left: 0.1em;
  right: 0.1em;
  bottom: 0.1em;
  border: 2px solid rgba(206, 230, 255, 0.249);
  /* kolor obramowania */
}

.compass-scale {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  /* aby nie przeszkadzały w interakcji z innymi elementami */
}

.circle.animate .compass-scale {
  display: block;
}

/* Definiowanie zmiennej dla rotacji */
.compass-scale div {
  --rotation: 0deg;
  position: absolute;
  width: 114%;
  height: 1px;
  background: linear-gradient(to right, #e7e7e7, transparent 5%, transparent 95%, #e7e7e7);
  left: 50%;
  top: 50%;
  transform-origin: 0 0;
  transform: rotate(var(--rotation)) translateX(-50%);
}

.compass-scale div.small-scale {
  height: 1px;
  width: 98%;
  background: linear-gradient(to right, #ffffff, transparent 3%, transparent 91%, #ffffff);
}

.compass-scale div span {
  position: absolute;
  font-size: 0.5em;
  color: #fff;
  transform: translate(20%, -4px) rotate(90deg);
  left: 100%;
}

/* Ustawienie rotacji i zmiennej dla każdego znacznika */
.compass-scale div:nth-child(1) {
  --rotation: -90deg;
}

.compass-scale div:nth-child(2) {
  --rotation: 0deg;
}

.compass-scale div:nth-child(3) {
  --rotation: 90deg;
}

.compass-scale div:nth-child(4) {
  --rotation: 180deg;
}

.compass-scale div:nth-child(5) {
  --rotation: -45deg;
}

.compass-scale div:nth-child(6) {
  --rotation: 45deg;
}

.compass-scale div:nth-child(7) {
  --rotation: 135deg;
}

.compass-scale div:nth-child(8) {
  --rotation: 225deg;
}


/* Animacja dla pierwszego znacznika */
@keyframes rotateMarker1 {
  from {
    transform: rotate(-90deg) translateX(-50%);
  }

  to {
    transform: rotate(270deg) translateX(-50%);
  }
}

/* Animacja dla drugiego znacznika */
@keyframes rotateMarker2 {
  from {
    transform: rotate(0deg) translateX(-50%);
  }

  to {
    transform: rotate(360deg) translateX(-50%);
  }
}

/* Animacja dla trzeciego znacznika */
@keyframes rotateMarker3 {
  from {
    transform: rotate(90deg) translateX(-50%);
  }

  to {
    transform: rotate(450deg) translateX(-50%);
  }
}

@keyframes rotateMarker4 {
  from {
    transform: rotate(180deg) translateX(-50%);
  }

  to {
    transform: rotate(540deg) translateX(-50%);
  }
}

/* Stosowanie animacji do znaczników */
.circle.animate .compass-scale div:nth-child(1) {
  animation: rotateMarker1 10s cubic-bezier(0.43, 1.17, 0.55, 0.46) infinite;
}

.circle.animate .compass-scale div:nth-child(2) {
  animation: rotateMarker2 20s cubic-bezier(0.18, 0.85, 0.56, -0.79) infinite
}

.circle.animate .compass-scale div:nth-child(3) {
  animation: rotateMarker3 30s cubic-bezier(0.42, 0, 0, 2.27) infinite
}

.circle.animate .compass-scale div:nth-child(4) {
  animation: rotateMarker4 40s linear(0 0%, 0.22 2.1%, 0.86 6.5%, 1.11 8.6%, 1.3 10.7%, 1.35 11.8%, 1.37 12.9%, 1.37 13.7%, 1.36 14.5%, 1.32 16.2%, 1.03 21.8%, 0.94 24%, 0.89 25.9%, 0.88 26.85%, 0.87 27.8%, 0.87 29.25%, 0.57 40.26%, 0.91 32.4%, 0.98 36.4%, 1.01 38.3%, 1.04 40.5%, 1.05 42.7%, 1.05 44.1%, 1.04 45.7%, 1 53.3%, 0.99 55.4%, 0.98 57.5%, 0.99 60.7%, 1 68.1%, 1.01 72.2%, 1 86.7%, 1 100%) infinite
}

/* Animacje dla nowych kresek */
@keyframes rotateMarker5 {
  from {
    transform: rotate(-45deg) translateX(-50%);
  }

  to {
    transform: rotate(315deg) translateX(-50%);
  }
}

@keyframes rotateMarker6 {
  from {
    transform: rotate(45deg) translateX(-50%);
  }

  to {
    transform: rotate(405deg) translateX(-50%);
  }
}

@keyframes rotateMarker7 {
  from {
    transform: rotate(135deg) translateX(-50%);
  }

  to {
    transform: rotate(495deg) translateX(-50%);
  }
}

@keyframes rotateMarker8 {
  from {
    transform: rotate(225deg) translateX(-50%);
  }

  to {
    transform: rotate(585deg) translateX(-50%);
  }
}

/* Stosowanie animacji do nowych kresek */
.circle.animate .compass-scale div:nth-child(5) {
  animation: rotateMarker5 40s linear(0 0%, 0.22 2.1%, 0.37 72.68%, 1.11 8.6%, 1.3 10.7%, 1.35 11.8%, 1.37 12.9%, 1.37 13.7%, 1.36 14.5%, 1.32 16.2%, 1.03 21.8%, 0.94 24%, 0.89 25.9%, 0.88 26.85%, 0.87 27.8%, 0.87 29.25%, 0.88 30.7%, 0.91 32.4%, 0.98 36.4%, 1.01 38.3%, 1.04 40.5%, 1.05 42.7%, 1.05 44.1%, 1.04 45.7%, 1 53.3%, 0.99 55.4%, 0.98 57.5%, 0.99 60.7%, 0.58 10.01%, 1.01 72.2%, 1 86.7%, 1 100%) infinite;
}

.circle.animate .compass-scale div:nth-child(6) {
  animation: rotateMarker6 30s linear(0 0%, 0 2.27%, 0.02 4.53%, 0.04 6.8%, -0.09 81.13%, 0.1 11.33%, 0.14 13.6%, 0.25 18.15%, 0.39 22.7%, 0.56 27.25%, 0.77 31.8%, 1 36.35%, 0.89 40.9%, 0.85 43.18%, 0.81 45.45%, 0.79 47.72%, 0.77 50%, 0.75 52.27%, 0.75 54.55%, 0.75 56.82%, 0.77 59.1%, 0.79 61.38%, 0.81 63.65%, 0.85 65.93%, 0.89 68.2%, 1 72.7%, 0.97 74.98%, 0.95 77.25%, 0.94 79.53%, 0.94 81.8%, 0.94 84.08%, 0.95 86.35%, 0.97 88.63%, 1 90.9%, 0.99 93.18%, 0.98 95.45%, 0.99 97.73%, 1 100%) infinite;
}

.circle.animate .compass-scale div:nth-child(7) {
  animation: rotateMarker7 20s linear infinite;
}

.circle.animate .compass-scale div:nth-child(8) {
  animation: rotateMarker8 10s linear infinite;
}