#apps {
    position: absolute;
    /* width: 100%; */
    height: 100vh;
    /* background-color: black; */
    /* overflow: hidden; */
    /* cursor: none; Ukrycie kursora */
    z-index: 100;
}

.star {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 50%;
    transform-origin: center;
}

.line {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.5);
    transform-origin: left;
}
