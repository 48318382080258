.main-menu {
  background-image: url('./img/menu-bg.png');
  transition: background-position 1.3s ease, background-size 2.3s ease;
}

.main-menu {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 11;
}

.main-menu-button-container {
  display: flex;
  justify-content: space-between;
  flex: 1;
}


@keyframes moveBackground {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: 200%;
  }
}

.main-menu-button-container {
  background-image: url('./img/fog-up-bg.png');
  background-repeat: repeat-x;
  animation: moveBackground 60s linear infinite;
  transition: background-size 3s linear;
}

.main-menu-button-container .box {
  flex: 1 1;
  text-align: center;
  padding: 0px;
  cursor: pointer;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.main-menu-button-container .box {
  border-radius: 4px;
  margin: 5px;
  backdrop-filter: blur(1px);
  margin-top: -41px;
  margin-bottom: 11px;
  padding: 0px;
}

.main-menu-button-container .box .box-label {
  font-size: 9px;
  font-weight: 800;
  width: 100%;
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.628);
  font-family: Arial, Helvetica, sans-serif;
  position: absolute;
}

.main-menu-button-container .box.active {
  flex: 1.4 1;
  /* background-color: #0000003f; */
  color: #fff;
  backdrop-filter: blur(0px);
  margin-top: -40px;
  padding: 0px;
}

.main-menu-button-container .box img,
.main-menu-button-container .box svg {

  width: auto;

  height: auto;

  max-width: 70%;

  max-height: 70%;

  object-fit: cover;

  transition: all 0.1s ease;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.box svg {
  fill: red;
  /* Zmienia kolor wypełnienia na czerwony */
}

.box.active img,
.box.active svg {
  /* Zaktualizowano selektor */
  transform: scale(1.1);
  margin-top: -15px;
  filter: drop-shadow(1px 6px 16px rgba(255, 255, 255, 1));
}