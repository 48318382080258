* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* .layout-container {
  display: flex;
  justify-content: center;
  align-items: center;} */

/* @media (orientation: landscape) { */
  /* .content-container {
      height: 90dvh;
      width: 100%; 
  } */
  /* } */

/* 

.layout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #f4f4f4; 
}

.content-container {
border: 1px solid black; 
background: #fff; 
flex-grow: 1;
display: flex;
box-sizing: border-box;
}


@media (orientation: portrait) {
.content-container {
    width: 100vw;
     height: calc(100vw * 9 / 16);
    max-height: 100vh; 
}
}


@media (orientation: landscape) {
.content-container {
    height: 100vh;
    width: calc(100vh * 9 / 16); 
}
} */